@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/Roboto-Italic.ttf') format('truetype');
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  margin: 0;
  padding: 0;
  width: 100%;
}
