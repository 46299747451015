#glossary-search input {
  padding: 0;
  /*font-size: 24px;*/
}

#glossary-search fieldset {
  border: none;
  borderWidth: 0;
}

/* @media (max-width:899.95px){
  #glossary-search input {
    padding: 0;
    font-size: 16px;
  }
} */

#glossary-search input::placeholder {
  color: #C8C8C8;
}
