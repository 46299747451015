/* #glossary-search fieldset {
  border: none;
  borderWidth: 0;
}

#glossary-search input {
  padding: 0;
  font-size: 14px;
}

#glossary-search input::placeholder {
  color: #C8C8C8;
} */


.glossary-filter-group .MuiTypography-root {
  font-size: 14px;
  font-family: Futura, sans-serif;
}

.glossary-filter-group span.MuiTypography-root {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}
