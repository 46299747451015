@media print {
  .no-break-inside {
    width: 100%;
    display: block !important;
    position: relative;
    page-break-before: auto;
    page-break-after:auto;
    page-break-inside: avoid;
  }

  .print-block {
    display: block !important;
  }
}

.icon svg {
  display: block;
  height: 100%;
}
